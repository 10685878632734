import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './app.css';
import StarWavePage from './pages/star-wave';

export default function App() {
    return <>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<StarWavePage />} />
                <Route path="/stars" element={<StarWavePage />} />
            </Routes>
        </BrowserRouter>
    </>
}
