import { useEffect } from "react"

export default function useEvent<K extends keyof WindowEventMap>(event: K, handler: (this: Window, ev: WindowEventMap[K]) => any) {
    useEffect(() => {
        // initiate the event handler
        window.addEventListener(event, handler, false);
    
        // this will clean up the event every time the component is re-rendered
        return function cleanup() {
            window.removeEventListener(event, handler);
        }
    });
}
