import { FC, useEffect, useRef, useState } from "react";
import useEvent from "../util";
import rick from '../assets/rick.png';
import confetti from 'canvas-confetti';

interface Particle {
    colour: string;
    radius: number;
    y: number;
    speedScale: number;
    xOffset: number;
}

function randomGrey(): string {
    const shade = 128 + Math.round(Math.random() * 128);
    return '#' + shade.toString(16) + '0000';
}

function generateParticles(): Particle[] {
    const particles: Particle[] = [];
    for (let i = 0; i < 256; i++) {
        const speedScale =  Math.random();
        const radius = Math.random() * speedScale;
        particles.push({
            colour: randomGrey(),
            speedScale: speedScale * 4 + 1,
            radius: radius * 4 + 1,
            y: Math.random(),
            xOffset: Math.random(),
        });
    }
    return particles;
}

const ANIMATION_LENGTH = 5000;

const StarWavePage: FC = () => {
    const ref = useRef<HTMLCanvasElement>(null);

    const [width, setWidth] = useState(document.body.clientWidth);
    const [height, setHeight] = useState(document.body.clientHeight);
    const [particles, _setParticles] = useState<Particle[]>(generateParticles());
    const [rickTop, _setRickTop] = useState(Math.random() * 50 + 25);

    useEvent('resize', () => {
        setWidth(document.body.clientWidth);
        setHeight(document.body.clientHeight);
    });

    useEffect(() => {
        setWidth(document.body.clientWidth);
        setHeight(document.body.clientHeight);
    });

    useEffect(() => {
        confetti({
            spread: 170,
            particleCount: 512,
            origin: {
                x: 0.5,
                y: 0.85,
            },
            decay: .95,
            ticks: 500,
        });
    }, []);

    const [frameCount, setFrameCount] = useState(0);
    const firstFrameTime = useRef(-1);
    useEffect(() => {
        const frameId = requestAnimationFrame((time) => {
            if (width !== document.body.clientWidth) {
                setWidth(document.body.clientWidth);
            }
            if (height !== document.body.clientHeight) {
                setHeight(document.body.clientHeight);
            }

            if (firstFrameTime.current === -1) {
                firstFrameTime.current = time;
            }
            const animationPosition = ((time - firstFrameTime.current) / (ANIMATION_LENGTH * width / 1000));
            const context = ref.current!.getContext('2d')!;
            context.clearRect(0, 0, width, height);
            for (const particle of particles) {
                const x = (1 - ((particle.speedScale * animationPosition) % 1)) * width;
                const y = particle.y * height;
                context.fillStyle = particle.colour;
                context.beginPath();
                context.arc(x, y, particle.radius, 0, 2 * Math.PI);
                context.fill();
            }
            setFrameCount(frameCount + 1);
        });
        return () => {
            cancelAnimationFrame(frameId);
        };
    }, [frameCount, setFrameCount, particles, ref]);

    return <main className="content">
        <canvas className="stars" width={width} height={height} ref={ref} />
        <img className="rick" src={rick} width={387} height={566} alt="rick astley" style={{ top: `${rickTop}vh` }} />
        <article className="message">
            <h1>Never gonna give you up...</h1>
            <h2>...even in space!</h2>
        </article>
        <footer className="feet">
            <a href="https://ashhhleyyy.dev/">
                Created with 💖 by Ashhhleyyy
            </a>
        </footer>
    </main>
}

export default StarWavePage;
